<template>
  <v-card flat>
    <v-form v-model="valid">
      <v-expansion-panels
        flat
        accordion
        v-model="openExpansionPanel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="text-capitalize text-h6">
            {{ $t('general') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label') + ' *'"
                  type="text"
                  required
                  v-model="upsellGroup.label"
                  :rules="[rules.required]"
                  outlined
                  dense
                  class="mb-2"
                />
              </v-col>
              
              <v-col cols="12">
                <v-text-field
                  :label="$t('description')"
                  type="text"
                  v-model="upsellGroup.description"
                  outlined
                  dense
                  class="mb-2"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  :label="$t('category') + ' *'"
                  required
                  v-model="selectedCategoryKey"
                  :items="categories"
                  item-text="label"
                  item-value="key"
                  :rules="[rules.required]"
                  outlined
                  dense
                  class="mb-2"
                  @change="handleCategoryChange"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :label="$t('sortingWeight')"
                  type="number"
                  v-model.number="upsellGroup.sortingWeight"
                  outlined
                  dense
                  class="mb-2"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'UpsellGroupForm',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      upsellGroup: null,
      selectedCategoryKey: null,
      openExpansionPanel: 0
    }
  },
  computed: {
    rules() {
      return this.$store.getters.rules
    },
    categories() {
      const categories = Array.isArray(this.$store.state.categories) 
        ? this.$store.state.categories.filter(category => category && category.active !== false) 
        : []
      return this.$store.getters.categoriesFormatForUiFields(categories)
    },
    editing() {
      return this.upsellGroup.uuid ? true : false
    }
  },
  watch: {
    valid(value) {
      this.$emit('valid', value)
    },
    selectedCategoryKey: {
      handler(value) {
        this.emitUpdatedValue()
      }
    },
    upsellGroup: {
      handler(value) {
        if (!value.label || !this.selectedCategoryKey) {
          this.$emit('valid', false)
          return
        }
        this.$emit('valid', true)
        this.emitUpdatedValue()
      },
      deep: true
    }
  },
  methods: {
    handleCategoryChange(value) {
      this.selectedCategoryKey = value
    },
    setFieldValues() {
      if (this.upsellGroup.category) {
        this.selectedCategoryKey = this.upsellGroup.category.uuid
      }
    },
    emitUpdatedValue() {
      if (this.upsellGroup) {
        const emitValue = {
          ...this.upsellGroup,
          category: this.selectedCategoryKey
        }
        this.$emit('input', emitValue)
      }
    }
  },
  mounted() {
    this.upsellGroup = { ...this.value }
    this.setFieldValues()
  }
}
</script>
